
import UITileButton from '@/components/ui/UITileButton/UITileButton.vue'
import PDESvg from '@/components/pde/PDESvg.vue'
import professionTilesList from './professionTilesList.js'
import { mapActions } from 'vuex'

export default {
  name: 'ProfessionTiles',

  components: {
    PDESvg,
    UITileButton,
  },
  emits: ['professionSelected'],

  computed: {
    professionTiles() {
      const tiles = professionTilesList[this.$globalVars.brand]

      // TODO: Remove this flag check once CRNA registration is live
      if (!this.$featureFlags.crnaRegistration11712) {
        return tiles.filter(tile => tile.name !== 'crna')
      }

      return tiles
    },
  },

  methods: {
    ...mapActions('analytics', ['sendClickEvent']),
    professionSelected({ payload }, text) {
      this.sendClickEvent({
        name: 'Profession Selection Click',
        text,
        area: 'login',
      })
      this.$emit('professionSelected', payload)
    },
  },
}
