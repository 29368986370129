import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["name"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("button", {
    name: $props.name,
    class: "bg-pureblack-0 w-full min-h-[130px] cursor-pointer border-0 border-solid border-transparent rounded-3 font-inherit",
    onClick: _cache[0] || (_cache[0] = (...args) => ($options.tileClick && $options.tileClick(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}